<template>
  <div v-if="schema.value">
    <div
        v-for="(value,index) in valueArray"
        :key="index"
        @keydown.enter="valueArray.push({'':''})"
    >
      <div class="row">
        <q-input
            :model-value="Object.keys(value)[0]"
            type="text"
            class="col-12 col-md-6 q-pa-xs"
            color="white"
            :label=labels(id)
            standout="bg-teal text-white"
            label-color="light"
            ref="id"
            @update:model-value="result =>handleChangeId(value, result , index)"
        />
        <q-input
            :model-value="Object.values(value)[0]"
            type="text"
            class=" col-md-6 q-pa-xs"
            color="white"
            :label=labels(sku)
            standout="bg-teal text-white"
            label-color="light"
            @update:model-value="result =>handleChange(value, result, index)"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <q-input
          :model-value="key"
          type="text"
          class="col-12 col-md-6 q-pa-xs"
          color="white"
          :label=labels(id)
          standout="bg-teal text-white"
          label-color="light"
          ref="id"
          @update:model-value="result =>handleChangeIdEmpty(result)"
      />
      <q-input
          :model-value="value"
          :disable="!key"
          type="text"
          class=" col-md-6 q-pa-xs"
          color="white"
          :label=labels(sku)
          standout="bg-teal text-white"
          label-color="light"
          @update:model-value="result =>handleChangeEmpty(result)"
      />
    </div>

  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      id: ' Id',
      sku:' SKU',
      schemaValue: this.schema.value || {},
      valueArray: [],
      key: '',
      value: ''
    }
  },
  mounted() {
    if(this.schema.value) {
      this.valueArray = Object.entries(this.schema.value).map(entry => {
        return {[entry[0]]: entry[1]};
      });
    }
  },
  methods: {
    labels (word = '') {
      const result = this.schema.label + ' ' + word
      return result
    },
   handleChange (name , value , index) {
     if( this.valueArray[index] ) {
       this.valueArray.splice(index, 1, {[Object.keys(name)[0]]: value})

       this.schemaValue = Object.assign({}, ...this.valueArray);
       this.schema.onChange(this.schemaValue, this.schema.value)

     }
     // this.schemaValue[name] = value
     //
     // this.schema.onChange(this.schemaValue, this.schema.value)

   },
   handleChangeId (id, result , index) {
      if( this.valueArray[index] ) {
        if(!this.valueArray[index][result]) {
          this.valueArray.splice(index, 1, {[result]: Object.values(id)[0]})

          this.schemaValue = Object.assign({}, ...this.valueArray);
          this.schema.onChange(this.schemaValue, this.schema.value)
        }
      }

      // if(!this.schemaValue[result]) {
      //   delete this.schemaValue[id]
      //
      //   this.schemaValue[result] = sku
      //
      //   this.schema.onChange(this.schemaValue, this.schema.value)
      // }
   },
   handleChangeIdEmpty (result) {
      this.key = result
      this.valueArray.push({[result]: ''})
     this.schemaValue = Object.assign({}, ...this.valueArray);
     this.schema.onChange(this.schemaValue, this.schema.value)
   },
   handleChangeEmpty (value) {
     this.value = value
     this.valueArray.push({'': value})
     this.schemaValue = Object.assign({}, ...this.valueArray);
     this.schema.onChange(this.schemaValue, this.schema.value)

   },
 }
}
</script>
